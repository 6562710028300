@font-face { font-family: 'Griffon'; src: url('./fonts/GriffonShadowNormal.ttf') format('truetype'); font-weight: 300; font-style: normal; }

.ccg-reset {
  margin: 0;
  padding: 0;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.btn-success {
  background-color: #52c41a;
  border-color: #52c41a;
  columns: white;
}

.btn-success:hover,
.btn-success:focus {
  background-color: #389e0d!important;
  border-color: #389e0d!important;
  color: white!important;
}

.ccg-center {
  text-align: center;
  padding-bottom: 30px;
}

.ccg-colors {
  display: flex;
  justify-content: end;
  font-size: 18px;
  margin-top: 10px;
  margin-right: 20px;
}

.ccg-header {
  display: flex;
  padding: 0;
  padding-top: "15px";
  padding-right: "40px";
  color: "white";
  background-color: "#283891";
  justify-content: flex-end;
}

.chat-layout {
  background-color: white!important;
  overflow: scroll;
}

.notifications-content {
  max-height: 60vh;
  overflow: scroll;
}

.ccg-projects {
  min-width: 80%;
  overflow-x: scroll;
}

/* Inicio - Solicitudes */
.ccg-chat-solicitudes {
  flex: 1; 
  overflow-y: auto; 
  background: '#238492'; 
  padding: '16px'; 
  display: 'flex'; 
  height: 45vh;
  flex-direction: 'column-reverse';
  border: 1px solid white;
  border-radius: 15px 15px 0 0;
  background-color: white;
}
/* Fin - Solicitudes */

/* Footer END */
.ccg-footer {
  margin: auto;
  padding: 0;
  text-align: "center"; 
  font-family: 'Griffon';
  font-size: 3vw;
}
