body {
  font-size: 'sans-serif';
  margin: 0;
}

.tbody {
  background-image: url('./img/login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: auto;
  opacity: .8;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login-content {
  max-width: 400px;
  height: 300px;
  padding: 20px 50px 50px 50px;
  border: 1px solid #fff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: auto;

}

.txt-center {
  text-align: center;
}

.mt-5 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 40px;
}

.login-messages {
  font-size: 25px;
}

.custom-alert {
  position: absolute;
  bottom: 20px;
  margin-top: 10px;
  z-index: 1;
}

.notifications {
  display: flexbox;
  justify-content: end;
  font-size: 18px;
}
